<template>
	<div
		class="bg-login flex-column justify-center full-height"
	>
		<div
			class="bg-login-content pa-10"
		>
			<div
				class="pa-10 text-center"
			>
				<div
					class="color-white pa-10 "
				> <img :src="require('@/assets/' + $logo)" class="width-80"> </div>

				<div class="font-weight-bold size-px-20">
					<div v-if="is_on_confirm">
						<button
							@click="is_on_auth = true"
							class="btn btn-primary"
						>본인인증</button>
					</div>
					<div v-else-if="is_on_error" class="color-red">
						{{ error_msg }}
					</div>
					<div v-else class="">
						WALLET SERVICE
					</div>
				</div>
			</div>
		</div>

		<AuthConfirm
			v-if="is_on_auth"
			confirm_type="api"

			@click="postLogin"
			@cancel="is_on_auth = false"
			@fail="fail"
		></AuthConfirm>
	</div>
</template>

<script>

import AuthConfirm from "@/view/Layout/AuthConfirm";

export default{
	name: 'Login'
	, components: {AuthConfirm}
	, props: ['Axios', 'codes']
	,data: function() {
		return {
			program: {
				name: '로그인'
				, code: 'login'
				, top: false
				, bottom: false
				, title: false
				, bland: false
				, wrap: 'loginNew'
			}
			, api_id: this.$route.query.api_id
			, access_token: this.$route.query.token
			, is_on_confirm: false
			, is_on_error: false
			, error_msg: ''
			, is_on_auth: false
		}
	}
	,computed: {
		title: function(){
			let t = process.env.VUE_APP_TITLE
			return t
		}
	}
	,methods: {
		login: async function(){

			try {
				let result = await this.$Axios({
					method: 'post'
					, url: this.$api_url.api_path.post_login
					, header: {
						access_token: this.access_token ? this.access_token : ''
					}
					, data: {
						api_id: this.api_id
					}
				})

				if (result.success) {
					this.is_on_confirm = true
				} else {
					throw result.message
				}
			} catch (e) {
				this.error_msg = e
				this.is_on_error = true
			}
		}
		, fail: function(message){
			this.$bus.$emit('notify', { type: 'error', message: message})
		}
		, postLogin: async function(e){
			e.api_id = this.api_id
			console.log(this.api_id, e)
			try{
				let result = await this.$Axios({
					method: 'post'
					, url: '/api/login'
					, header: {
						access_token: this.access_token ? this.access_token : ''
					}
					, data: e
				})

				if (result.success) {

					sessionStorage.setItem(process.env.VUE_APP_NAME + 'T', result.data.access_token)
					sessionStorage.setItem(process.env.VUE_APP_NAME + 'T2', result.data.api_id)
					sessionStorage.setItem(process.env.VUE_APP_NAME + 'T3', result.data.session_key)

					localStorage.removeItem(process.env.VUE_APP_NAME + 'A')
					localStorage.removeItem(process.env.VUE_APP_NAME + 'N')
					localStorage.removeItem(process.env.VUE_APP_NAME + 'P')

					this.toMain()
				} else {
					throw result.message
				}
			}catch (e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}

		,toMain: function(){
			document.location.href = '/Index'
		}
	}
	,mounted() {
		this.login()
	}
	,created: function(){
		this.$emit('onLoad', this.program)

	}
	,watch: {

	}
}
</script>

<style>
.bg-login { background-color: #eee; padding: 10px;}
.bg-login-content { background-color: white; border-radius: 5px}

.auto-login { vertical-align: middle; font-size: 14px; }

.login-menu { }
.login-menu a { font-size: 14px; font-weight: 400}

.toggle-password { position: relative; }
.toggle-password .toggle { position: absolute; right: 10px; top: 20px; color: #bbb}
.toggle-password .toggle.on { color: #0f6ecd}

.error-underline { background: none; border-bottom: 1px solid red !important;}

</style>